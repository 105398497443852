<template>
  <LiefengContent>
    <template #title>概况 </template>
    <template v-slot:toolsbarLeft>
      <DatePicker
       :options="options"
        @on-change="changeTime"
        type="daterange"
        placement="bottom-end"
        placeholder="选择日期"
        class="picker"
        v-model="timeData"
        :clearable="false"
        :disabled='disabled'
      ></DatePicker>
    </template>
    <template v-slot:toolsbarRight>
      <Timedropdown :disabled='disabled' @changeTime="getTime"></Timedropdown>
      <!-- <Button @click="$router.push('/appletsurvey')" type="primary"
        >概况</Button
      >
      <Button @click="$router.push('/accesstrends')">访问趋势</Button>
      <Button @click="$router.push('/accessspread')">访问分布</Button>
      <Button @click="$router.push('/savedata')">数据展示</Button> -->
      <!-- <Button @click="$router.push('/userportrait')">用户画像</Button> -->
    </template>

    <template #contentArea>
      <div class="box">
        <CardItem
          cardTitle="累计用户数"
          :cardTime="cardTime"
          :cardData="userCard"
        ></CardItem>
        <CardItem
          cardTitle="转发人数"
          :cardTime="cardTime"
          :cardData="forwardUser"
        ></CardItem>
        <CardItem
          cardTitle="转发次数"
          :cardTime="cardTime"
          :cardData="forwardNum"
        ></CardItem>
      </div>
      <Card>
        <div class="btn_list">
          <Button @click="onType(0)" :type="index==0?'primary':''">累计用户数</Button>
          <Button @click="onType(1)" :type="index==1?'primary':''">转发人数</Button>
          <Button @click="onType(2)" :type="index==2?'primary':''">转发次数</Button>
        </div>
        <Vline class="line" :data="lineData"></Vline>
      </Card>
    </template>
  </LiefengContent>
</template>

<script>
import LiefengContent from "./children/LiefengContent";
import Timedropdown from "./children/timedropdown";
import Vline from "v-charts/lib/line.common";
import CardItem from "./children/carditem";
export default {
  data() {
    return {
        disabled:false,
        index:0,
        options: {
          disabledDate(date) {
            return date && date.valueOf() > new Date() - 1000 * 60 * 60 * 24 * 1;
          }
        },
      timeData: [
        this.$core.formatDate(
          new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 7),
          "yyyy-MM-dd"
        ),
        this.$core.formatDate(
          new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 1),
          "yyyy-MM-dd"
        ),
      ],
      cardTime: "",
      lineData:{},
      userData: {
        columns: ["时间", "累计用户"],
        rows: [
        ],
      },
      shareUserData: {
        columns: ["时间", "转发人数"],
        rows: [
        ],
      },
     shareNumData: {
        columns: ["时间", "转发次数"],
        rows: [
        ],
      },
      dataCard: {
        num: 999,
        unit: "人",
        day: 20,
        week: -10,
        mounth: -60,
      },
      userCard: {},
      forwardNum: {},
      forwardUser: {},
    };
  },
  created() {
    this.lineData = this.userData
    this.getData();
  },
  methods: {
    getTime(val) {
      this.timeData = val;
      this.getData();
    },
    getData() {
      this.disabled = true
      this.cardTime = this.timeData[1];
      this.userData.rows = []
      this.shareUserData.rows = []
      this.shareNumData.rows = []
      this.$Message.loading({
        content: "数据正在加载中",
        duration: 0
      });
      this.$get("/statistic/api/symanage/pc/weappStat/getAllData", {
        startDate: this.timeData[0],
        endDate: this.timeData[1],
      }).then((res) => {
        this.disabled = false
        this.$Message.destroy();
        if (res.code == 200 && res.data) {
          let data = res.data;
          this.userCard = {
            num: data.weappDailyStatTotalVoList[
              data.weappDailyStatTotalVoList.length - 1
            ].totalUser,
            unit: "人",
            day: data.dayHb.totalUser * 100,
            week: data.weekHb.totalUser * 100,
            mounth: data.monthHb.totalUser * 100,
          };
          this.forwardNum = {
            num: data.weappDailyStatTotalVoList[
              data.weappDailyStatTotalVoList.length - 1
            ].shareNum,
            unit: "次",
            day: data.dayHb.shareNum * 100,
            week: data.weekHb.shareNum * 100,
            mounth: data.monthHb.shareNum * 100,
          };
          this.forwardUser = {
            num: data.weappDailyStatTotalVoList[
              data.weappDailyStatTotalVoList.length - 1
            ].shareUser,
            unit: "人",
            day: data.dayHb.shareUser * 100,
            week: data.weekHb.shareUser * 100,
            mounth: data.monthHb.shareUser * 100,
          };
          data.weappDailyStatTotalVoList.map(item => {
             this.userData.rows.push(
                {时间:item.statDate,累计用户:item.totalUser}
             ) 
             this.shareUserData.rows.push(
                {时间:item.statDate,转发人数:item.shareUser}
             ) 
             this.shareNumData.rows.push(
                {时间:item.statDate,转发次数:item.shareNum}
             ) 
          })
        }else {
          
            this.$Message.error({
              content:'获取数据失败',
              background:true
            })
          }
      });
    },
    changeTime(val) {
      this.timeData[0] = val[0];
      this.timeData[1] = val[1];
      this.getData();
    },
    onType(index){
      this.index = index
        if(index == 0){
            this.lineData = this.userData
        }else if(index == 1){
            this.lineData = this.shareUserData
        }else {
            this.lineData = this.shareNumData
        }
    }
  },
  components: {
    LiefengContent,
    Timedropdown,
    Vline,
    CardItem,
  },
};
</script>
    
<style scoped lang='less'>
/deep/.ivu-card-head {
  border: none;
}
.btn_list{
    button{
        margin-right:10px
    }
}
.picker {
  position: absolute;
  right: 0;
}
.ivu-dropdown {
  line-height: 32px;

  /deep/.ivu-select-dropdown {
    top: 31px !important;
    left: 0 !important;
    height: 263px !important;
    width: 206px !important;
    padding: 0 !important;
  }
  /deep/.ivu-btn {
    width: 102px !important;
    height: 43px;
    line-height: 43px;
    margin: 0 1px 1px 0;
    border: none;
  }
}

.line {
  width: 100%;
  height: 100%;
}
.box {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  // margin-bottom: 20px;
}
.card {
  width: 30%;
  margin-bottom: 20px;
  padding: 20px;
  .top {
    .title {
      // line-height: 20px;
      font-size: 20px;
      span {
        font-size: 14px;
        border: 1px solid #888888;
        border-radius: 50%;
      }
    }
  }
  .bottom {
    display: flex;
    justify-content: space-between;
    .left {
      position: relative;
      p {
        margin-right: 10px;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 200px;
        span {
          font-size: 26px;
          margin-right: 10px;
        }
      }
    }
    .right {
      span {
        padding-left: 30px;
      }
      .red {
        color: red;
      }
      .green {
        color: green;
      }
    }
  }
}
</style>